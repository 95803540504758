import BaseService from '../../BaseService.js'

export default class FooterService extends BaseService {

    constructor() {
        super('version')
    }

    async versionQuery() {
        await this.retrieveQueryUrl();
        return fetch(this.queryUrl, {
            method: 'GET',
            cache: 'reload'
        })
            .then(result => result.json())
            .catch(error => {
                this.logging.log(error);
            });
    }
}