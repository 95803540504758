import WebComponent from '../../WebComponent.js';
import I18n from '../../i18n.js';
import { html, directive } from '../../_snowpack/pkg/lit-html.js';
import BranchenService from "./BranchenService.js";
import WarnsubscriptionService from "./WarnsubscriptionService.js";
import QuickNavigation from '../QuickNavigation/QuickNavigation.js';

export default class WarningSubscription extends WebComponent {

    constructor() {
        super();
        this.forceWrite = directive((value) => (part) => {
            part.setValue(value);
        });
        this.selectedSubscriptionCommunicationChannelValue = '';
        this.selectedUnsubscriptionCommunicationChannelValue = '';
        this.emailMaxLength = 100;
        this.smsMaxLength = 15;
        this.emailValidationPattern = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))'
            + '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
        this.phoneValidationPattern = "\\d{5," + this.smsMaxLength + "}";
        this.selectedBranches = [];
        this.branches = [];
        this.subscriptionValidationErrors = [];
        this.unsubscriptionValidationErrors = [];
        this.prefixes = {ch: "0041", fl: "00423", de: "0049", fr: "0033", at: "0043", it: "0039"}
        this.selectedSubscriptionCommunicationChannel = 'sms';
        this.selectedUnsubscriptionCommunicationChannel = 'sms';
        this.communicationChannels = ['sms', 'email'];
        this.selectedProductOption = 'all';
        this.productOptions = ['all', 'some'];
        this.selectedSubscriptionPrefix = {country: 'ch', prefix: this.prefixes.ch};
        this.selectedUnsubscriptionPrefix = {country: 'ch', prefix: this.prefixes.ch};
    }

    connectedCallback() {
        this.changeLanguageListener = () => {
            this.render();
        };
        this.wirtschaftszweigFilterChangedListener = e => {
            sessionStorage.setItem('wirtschaftszweigId', e.detail);
            window.location.hash = 'Recalls';
        };
        document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.changeLanguageListener);
        document.addEventListener(QuickNavigation.EVENT_KEYS.WIRTSCHAFTSZWEIG_FILTER_CHANGED, this.wirtschaftszweigFilterChangedListener);
        this.renderBranches();
    }

    disconnectedCallback() {
        document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.changeLanguageListener);
        document.removeEventListener(QuickNavigation.EVENT_KEYS.WIRTSCHAFTSZWEIG_FILTER_CHANGED, this.wirtschaftszweigFilterChangedListener);
    }

    get translationFile() {
        return './views/WarningSubscription/i18n.json'
    }

    get cssFile() {
        return './views/WarningSubscription/style.css';
    }

    getTemplate() {
        return html`
            ${this.htmlLinks}
            <div class="main_wrapper">
                ${this.htmlDescription}
                <div class="divider"></div>
                ${this.htmlSubscriptionChannelsSelection}
                <div class="divider"></div>
                ${this.htmlProductionOptions}
                ${this.htmlSubscription}
                <div class="divider"></div>
                ${this.htmlUnsubscription}
            </div>
        `
    }

    get htmlLinks() {
        return html`
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.css"
                  integrity="sha256-EyIocnfi8LE28Ogio6vh1jzAztY8lXFrHvAGviJ7cVQ="
                  crossorigin="anonymous">
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/button.css"
                  integrity="sha256-wpzJilWuuuCiST+1kQ6j3vLpVZT7CVZ2WsVtcdA5cKE="
                  crossorigin="anonymous">
            <link rel="stylesheet"
                  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/list.css"
                  integrity="sha256-A2c05RTW3wySXORRcMvhGFVzEF4lKGOxXawOjaUlNoE="
                  crossorigin="anonymous">
        `
    }

    get htmlDescription() {
        return html`
            <div class="wrapper_col_dir">
                <div class="vertical_distance" style="margin-top:0px;">
                    ${this.i18n.translate('warning.subscription.header.description')}
                </div>
            </div>
        `
    }

    get htmlSubscriptionChannelsSelection() {
        let _channels = [];
        for (let channel of this.communicationChannels) {
            _channels.push(this.getHtmlSubscriptionChannelSelection(channel));
        }
        return html`
            <div class="wrapper_col_dir">
                <div class="vertical_distance header_text">
                    ${this.i18n.translate('warning.subscription.channel.selection.header')}
                </div>
                <div class="vertical_distance">${this.i18n.translate('warning.subscription.channel.selection')}</div>
                <div class="vertical_distance">${_channels}</div>
                ${this.htmlSmsExplanation}
            </div>
        `
    }

    getHtmlSubscriptionChannelSelection(channelId) {
        const buttons = channelId === 'sms'
            ? this.getSubscriptionHtmlFlagButton(this.selectedSubscriptionPrefix.country)
            : '';
        const keyDownHandler = channelId === 'sms' ? this.telephoneKeyDownHandler : () => true;
        const pasteForTelephoneHandler = channelId === 'sms' ? this.telephonePasteHandler : () => true;
        const inputType = channelId === 'sms' ? "number" : "email";
        return html`
            <div class="wrapper_row_dir vertical_distance_bottom" @click="${_ => {
                this.onClickSubscriptionCommunicationChannel(channelId)
            }}">
                <i id="${channelId}" class="${this.getSubscriptionChannelSelectionClass(channelId)}"></i>
                <label class="bold channel_label"
                       for="${channelId}">${this.i18n.translate('warning.subscription.channel.selection.label.' + channelId)}</label>
                ${buttons}
                <input type="${inputType}"
                       id="${'subscription' + channelId + 'InputText'}"
                       .value="${this.forceWrite(this.getSubscriptionChannelSelectionValue(channelId))}"
                       @keydown="${keyDownHandler}"
                       @paste="${pasteForTelephoneHandler}"
                       @change="${e => {
                           this.onChangeSubscriptionSelectionChannelValue(e)
                       }}"
                       placeholder="${this.i18n.translate('warning.subscription.channel.selection.placeholder.' + channelId)}"
                       maxlength="${this[channelId + 'MaxLength']}">
            </div>
        `
    }

    getHtmlUnsubscriptionChannelSelection(channelId) {
        const buttons = channelId === 'sms'
            ? this.getUnsubscriptionHtmlFlagButton(this.selectedUnsubscriptionPrefix.country)
            : '';
        const keyDownHandler = channelId === 'sms' ? this.telephoneKeyDownHandler : () => true;
        const pasteForTelephoneHandler = channelId === 'sms' ? this.telephonePasteHandler : () => true;
        const inputType = channelId === 'sms' ? "number" : "email";
        return html`
            <div class="wrapper_row_dir vertical_distance_bottom" @click="${_ => {
                this.onClickUnsubscriptionCommunicationChannel(channelId)
            }}">
                <i id="${channelId}" class="${this.getUnsubscriptionChannelSelectionClass(channelId)}"></i>
                <label class="bold channel_label"
                       for="${channelId}">${this.i18n.translate('warning.subscription.channel.selection.label.' + channelId)}</label>
                ${buttons}
                <input type="${inputType}"
                       id="${'unsubscription' + channelId + 'InputText'}"
                       .value="${this.forceWrite(this.getUnsubscriptionChannelSelectionValue(channelId))}"
                       @keydown="${keyDownHandler}"
                       @paste="${pasteForTelephoneHandler}"
                       @change="${e => {
                           this.onChangeUnsubscriptionSelectionChannelValue(e)
                       }}"
                       placeholder="${this.i18n.translate('warning.subscription.channel.selection.placeholder.' + channelId)}"
                       maxlength="${this[channelId + 'MaxLength']}">
            </div>
        `
    }

    get telephoneKeyDownHandler() {
        return (evt) => {
            if (evt.key === 'Backspace')
                return true;
            if (evt.ctrlKey && (evt.keyCode === 86 || evt.keyCode === 67)) {
                return true;
            }
            if (!/^[0-9]+$/.test(evt.key)) {
                evt.preventDefault();
                return false;
            }
            return true;
        }
    }

    get telephonePasteHandler() {
        return (evt) => {
            let paste = (evt.clipboardData || window.clipboardData).getData('text');
            if (!/^[0-9]+$/.test(paste)) {
                evt.preventDefault();
                return false;
            }
            return true;
        }
    }

    get htmlSmsExplanation() {
        return html`
            <span class="sms_explanation">
                ${this.i18n.translate('warning.subscription.channel.selection.prefix')}
            </span>
        `
    }

    getSubscriptionHtmlFlagButton(prefix) {
        // https://github.com/lipis/flag-icon-css/tree/master/flags
        return html`
            <span
                    class="flag"
                    style="background: transparent url('./images/flags/${prefix}.svg') no-repeat;"
                    @click="${evt => {
                        this.onClickSubscriptionFlag(evt)
                    }}">
            </span>
        `
    }

    getUnsubscriptionHtmlFlagButton(prefix) {
        // https://github.com/lipis/flag-icon-css/tree/master/flags
        return html`
            <span
                    class="flag"
                    style="background: transparent url('./images/flags/${prefix}.svg') no-repeat;"
                    @click="${evt => {
                        this.onClickUnsubscriptionFlag(evt)
                    }}">
            </span>
        `
    }

    onClickSubscriptionFlag(evt) {
        const countries = Object.keys(this.prefixes);
        const index = countries.indexOf(this.selectedSubscriptionPrefix.country);
        let indice = index + 1;
        if (indice >= countries.length) {
            indice = 0;
        }
        this.selectedSubscriptionPrefix.country = countries[indice];
        this.selectedSubscriptionPrefix.prefix = this.prefixes[this.selectedSubscriptionPrefix.country];
        this.render();
    }

    onClickUnsubscriptionFlag(evt) {
        const countries = Object.keys(this.prefixes);
        const index = countries.indexOf(this.selectedUnsubscriptionPrefix.country);
        let indice = index + 1;
        if (indice >= countries.length) {
            indice = 0;
        }
        this.selectedUnsubscriptionPrefix.country = countries[indice];
        this.selectedUnsubscriptionPrefix.prefix = this.prefixes[this.selectedUnsubscriptionPrefix.country];
        this.render();
    }

    get htmlProductionOptions() {
        let _options = [];
        for (let option of this.productOptions) {
            _options.push(this.getHtmlProductOption(option));
        }
        return html`
            <div class="wrapper_col_dir">
                <div class="vertical_distance">${this.i18n.translate('warning.subscription.product.selection')}</div>
                <div>${_options}</div>
            </div>
        `
    }

    getHtmlProductOption(option) {
        if (option === 'some') {
            return html`
                <ul>
                    <li>
                        <div class="wrapper_row_dir" @click="${_ => {
                            this.onClickProductOption(option)
                        }}">
                            <i id="${option}" class="${this.getProductOptionClass(option)}"></i>
                            <label class="bold"
                                   for="${option}">${this.i18n.translate('warning.subscription.product.selection.' + option)}</label>
                        </div>
                    </li>
                    <li class="branches_distance">${this.htmlBranches}</li>
                </ul>
            `
        }
        return html`
            <div class="wrapper_row_dir" @click="${_ => {
                this.onClickProductOption(option)
            }}">
                <i id="${option}" class="${this.getProductOptionClass(option)}"></i>
                <label class="bold"
                       for="${option}">${this.i18n.translate('warning.subscription.product.selection.' + option)}</label>
            </div>
        `
    }

    get htmlSubscription() {
        let _errorMessages = [];
        for (let validationError of this.subscriptionValidationErrors) {
            _errorMessages.push(this.getHtmlValidationError(validationError));
        }
        return html`
            <div class="wrapper_col_dir">
                <div>${this.i18n.translate('warning.subscription.action.buttons.subscribe.info')}</div>
                ${_errorMessages}
                <div class="buttons_container align_center vertical_distance">
                    <button
                            id="subscriptionButton"
                            @click="${() => this.onClickSubscriptionButton()}"
                            class="ui button action_button">
                        ${this.i18n.translate('warning.subscription.action.buttons.subscribe')}
                    </button>
                </div>
            </div>
        `
    }

    get htmlUnsubscription() {
        let _errorMessages = [];
        for (let validationError of this.unsubscriptionValidationErrors) {
            _errorMessages.push(this.getHtmlValidationError(validationError));
        }
        let _channels = [];
        for (let channel of this.communicationChannels) {
            _channels.push(this.getHtmlUnsubscriptionChannelSelection(channel));
        }
        return html`
            <div class="wrapper_col_dir">
                <div class="vertical_distance header_text">${this.i18n.translate('warning.subscription.unsubscribe')}
                </div>
                <div class="vertical_distance">
                    ${this.i18n.translate('warning.subscription.action.unsubscription.question')}
                </div>
                <div class="vertical_distance">${_channels}</div>
                ${this.htmlSmsExplanation}
                <div class="vertical_distance"></div>
                <div>${this.i18n.translate('warning.subscription.action.buttons.unsubscribe.info')}</div>
                ${_errorMessages}
                <div class="buttons_container align_center vertical_distance">
                    <button
                            id="unsubscriptionButton"
                            @click="${() => this.onClickUnsubscriptionButton()}"
                            class="ui button action_button">
                        ${this.i18n.translate('warning.subscription.action.buttons.unsubscribe')}
                    </button>
                </div>
            </div>
        `
    }

    getHtmlValidationError(errorMessage) {
        return html`
            <p class="errors">${errorMessage}</p>
        `
    }

    renderBranches() {
        new BranchenService()
            .retrieveAll()
            .then(branches => {
                for (let branch of branches) {
                    console.dir(branch);
                }
                this.branches = branches.sort(this.sortBranches('nameDe'));
                this.render();
            })
            .catch(e => {
                console.log('Error when getting branches', e)
            });
    }

    sortBranches(property) {
        return function (a, b) {
            if (a[property].replace('Ü','ZZZ') > b[property].replace('Ü','ZZZ'))
                return 1;
            else if (a[property].replace('Ü','ZZZ') < b[property].replace('Ü','ZZZ'))
                return -1;
            return 0;
        }
    }

    get htmlBranches() {
        let _branches = [];
        const lang = getLanguage().charAt(0).toUpperCase() + getLanguage().slice(1);
        for (let branch of this.branches) {
            _branches.push(this.getHtmlBranch(branch.id, branch["name" + lang]));
        }
        return html`
            <div class="wrapper_col_dir">
                ${_branches}
            </div>
        `
    }

    getHtmlBranch(id, value) {
        return html`
            <div class="wrapper_row_dir">
                <input
                        type="checkbox"
                        id="cb${id}"
                        @click=${e => this.toggleBranchOption(e, id.toString())}
                        .checked=${this.forceWrite(this.selectedBranches[id.toString()])}
                        .disabled=${(this.selectedProductOption !== 'some') ? true : false}
                        value="${id}">
                <label for="cb${id}">${value}</label>
            </div>
        `;
    }

    onClickSubscriptionCommunicationChannel(channelId) {
        if (this.selectedSubscriptionCommunicationChannel === channelId) return;
        this.selectedSubscriptionCommunicationChannel = channelId;
        this.selectedSubscriptionCommunicationChannelValue = '';
        this.render();
    }

    onClickUnsubscriptionCommunicationChannel(channelId) {
        if (this.selectedUnsubscriptionCommunicationChannel === channelId) return;
        this.selectedUnsubscriptionCommunicationChannel = channelId;
        this.selectedUnsubscriptionCommunicationChannelValue = '';
        this.render();
    }

    getSubscriptionChannelSelectionClass(channelId) {
        return (channelId === this.selectedSubscriptionCommunicationChannel)
            ? 'circle check icon'
            : 'circle outline icon';
    }

    getUnsubscriptionChannelSelectionClass(channelId) {
        return (channelId === this.selectedUnsubscriptionCommunicationChannel)
            ? 'circle check icon'
            : 'circle outline icon';
    }

    getSubscriptionChannelSelectionValue(channelId) {
        return (channelId === this.selectedSubscriptionCommunicationChannel)
            ? this.selectedSubscriptionCommunicationChannelValue
            : "";
    }

    getUnsubscriptionChannelSelectionValue(channelId) {
        return (channelId === this.selectedUnsubscriptionCommunicationChannel)
            ? this.selectedUnsubscriptionCommunicationChannelValue
            : "";
    }

    onChangeSubscriptionSelectionChannelValue(evt) {
        this.selectedSubscriptionCommunicationChannelValue = evt.target.value;
    }

    onChangeUnsubscriptionSelectionChannelValue(evt) {
        this.selectedUnsubscriptionCommunicationChannelValue = evt.target.value;
    }


    getProductOptionClass(option) {
        return (option === this.selectedProductOption)
            ? 'circle check icon'
            : 'circle outline icon';
    }

    onClickProductOption(option) {
        if (this.selectedProductOption === option) return;
        this.selectedProductOption = option;
        this.selectedBranches = [];
        this.render();
    }

    toggleBranchOption(evt, id) {
        if (!evt.target.checked) {
            delete this.selectedBranches[id];
            return;
        }
        this.selectedBranches[id] = evt.target.checked;
    }

    onClickSubscriptionButton() {
        this.validateSubscription();
        if (this.subscriptionValidationErrors.length === 0) {
            this.doSubscription();
        }
        this.render();
    }

    onClickUnsubscriptionButton() {
        this.validateUnsubscription();
        if (this.unsubscriptionValidationErrors.length === 0) {
            this.doUnsubscription();
        }
        this.render();
    }

    validateUnsubscription() {
        this.unsubscriptionValidationErrors = [];
        this.validateUnsubscriptionCommunicationChannels();
    }

    validateSubscription() {
        this.subscriptionValidationErrors = [];
        this.validateSubscriptionCommunicationChannels();
        this.validateBranches();
    }

    validateSubscriptionCommunicationChannels() {
        if (this.selectedSubscriptionCommunicationChannelValue) {
            if (this.selectedSubscriptionCommunicationChannel === 'sms') {
                const regex = new RegExp(this.phoneValidationPattern);
                const match = regex.test(this.selectedSubscriptionCommunicationChannelValue);
                if (!match) {
                    this.subscriptionValidationErrors.push(this.i18n.translate('warning.subscription.error.pattern.field.sms'));
                }
            } else if (this.selectedSubscriptionCommunicationChannel === 'email') {
                const regex = new RegExp(this.emailValidationPattern);
                const match = regex.test(this.selectedSubscriptionCommunicationChannelValue);
                if (!match) {
                    this.subscriptionValidationErrors.push(this.i18n.translate('warning.subscription.error.pattern.field.email'));
                }
            }
        }
        if (!this.selectedSubscriptionCommunicationChannelValue) {
            this.subscriptionValidationErrors.push(this.i18n.translate('warning.subscription.error.mandatory.field.smsOrEmail'));
        }
        if (!this.isValidSwissNumberForSubscription()) {
            this.subscriptionValidationErrors.push(this.i18n.translate('warning.subscription.error.swiss.number.starts.with.zero'));
        }
    }

    validateUnsubscriptionCommunicationChannels() {
        if (this.selectedUnsubscriptionCommunicationChannelValue) {
            if (this.selectedUnsubscriptionCommunicationChannel === 'sms') {
                const regex = new RegExp(this.phoneValidationPattern);
                const match = regex.test(this.selectedUnsubscriptionCommunicationChannelValue);
                if (!match) {
                    this.unsubscriptionValidationErrors.push(this.i18n.translate('warning.subscription.error.pattern.field.sms'));
                }
            } else if (this.selectedUnsubscriptionCommunicationChannel === 'email') {
                const regex = new RegExp(this.emailValidationPattern);
                const match = regex.test(this.selectedUnsubscriptionCommunicationChannelValue);
                if (!match) {
                    this.unsubscriptionValidationErrors.push(this.i18n.translate('warning.subscription.error.pattern.field.email'));
                }
            }
        }
        if (!this.selectedUnsubscriptionCommunicationChannelValue) {
            this.unsubscriptionValidationErrors.push(this.i18n.translate('warning.subscription.error.mandatory.field.smsOrEmail'));
        }
        if (!this.isValidSwissNumberForUnsubscription()) {
            this.unsubscriptionValidationErrors.push(this.i18n.translate('warning.subscription.error.swiss.number.starts.with.zero'));
        }
    }

    isValidSwissNumberForSubscription() {
        if (this.selectedSubscriptionCommunicationChannel === 'sms')
            if (this.selectedSubscriptionPrefix.prefix === this.prefixes.ch)
                if (this.selectedSubscriptionCommunicationChannelValue.toString().startsWith("0"))
                    return false;
        return true;
    }

    isValidSwissNumberForUnsubscription() {
        if (this.selectedUnsubscriptionCommunicationChannel === 'sms')
            if (this.selectedUnsubscriptionPrefix.prefix === this.prefixes.ch)
                if (this.selectedUnsubscriptionCommunicationChannelValue.toString().startsWith("0"))
                    return false;
        return true;
    }

    validateBranches() {
        if (this.selectedProductOption === 'some') {
            if (Object.values(this.selectedBranches).every(e => e === false)) {
                this.subscriptionValidationErrors.push(this.i18n.translate('warning.subscription.error.mandatory.field.branches.some'));
            }
        }
    }

    get subscriptionDTO() {
        const telephone = (this.selectedSubscriptionCommunicationChannel === 'sms')
            ? this.selectedSubscriptionPrefix.prefix + this.selectedSubscriptionCommunicationChannelValue
            : '';
        const email = (this.selectedSubscriptionCommunicationChannel === 'email')
            ? this.selectedSubscriptionCommunicationChannelValue
            : '';
        let _branches = (this.selectedProductOption === 'some') ? this.formattedBranchesId : [];
        const isAlleBranchen = (this.selectedProductOption === 'all') ? true : false;

        return {
            "telephone": telephone,
            "email": email,
            "branches": _branches,
            "isAlleBranchen": isAlleBranchen,
            "language": getLanguage()
        }
    }

    getBranchDTO(branchId) {
        return {"id": branchId}
    }

    get unsubscriptionParameters() {
        const telephone = (this.selectedUnsubscriptionCommunicationChannel === 'sms')
            ? this.selectedUnsubscriptionPrefix.prefix + this.selectedUnsubscriptionCommunicationChannelValue
            : "";
        const email = (this.selectedUnsubscriptionCommunicationChannel === 'email')
            ? this.selectedUnsubscriptionCommunicationChannelValue
            : "";
        let parameters = [];
        parameters.push({key: "telephone", value: telephone});
        parameters.push({key: "email", value: email});
        return parameters;
    }

    get formattedBranchesId() {
        return Object
            .keys(this.selectedBranches)
            .filter(k => this.selectedBranches[k])
            .map((key) => {
                return this.getBranchDTO(Number(key));
            });
    }

    handleSubscriptionUnsubscriptionResponses(response, subscribeOrUnsubscribe = "") {
        if (!response) return;
        if (response.status >= 200 && response.status < 300) {
            sessionStorage.setItem("message",
                this.i18n.translate('warning.subscription.confirmation.'
                    + subscribeOrUnsubscribe.toLowerCase()
                    + '.'
                    + this['selected' + subscribeOrUnsubscribe + 'CommunicationChannel']));
            window.location.hash = 'WarningSubscription/confirmation';
        } else {
            response.text().then(res => {
                res = JSON.parse(res);
                sessionStorage.setItem("message",
                    this.i18n.translate(res.type));
                window.location.hash = 'WarningSubscription/confirmation';
            });
        }
    }

    doSubscription() {
        const service = new WarnsubscriptionService();
        service.post(this.subscriptionDTO)
            .then(res => {
                this.handleSubscriptionUnsubscriptionResponses(res, "Subscription");
                // matomo specific event to track subscriptions
                if (_paq !== null && typeof (_paq) !== 'undefined') {
                    _paq.push(['trackEvent', 'WarningSubscription',
                        'Subscription for: email:' + this.subscriptionDTO.email + ',telephone:' + this.subscriptionDTO.telephone,
                        'Tracked branches:' + JSON.stringify(this.subscriptionDTO.branches)]);
                }
            })
            .catch(error => console.log('ERROR when posting subscription: ', error));
    }

    doUnsubscription() {
        const service = new WarnsubscriptionService();
        service.sendUnsubscribeMessage(this.unsubscriptionParameters)
            .then(res => {
                this.handleSubscriptionUnsubscriptionResponses(res, "Unsubscription");
            })
            .catch(error => console.log('ERROR when getting unsubscription: ', error));
    }

}

customElements.define('customer-access-warning-subscription', WarningSubscription)